/* Button.tsx */

import React, { useContext } from "react";
import { Context } from "../Context";
import { useNavigate } from "react-router-dom";

interface Profile {
  label: string;
  disabled: boolean;
  addClasses: string;
  action: string;
  id: string;
  table: string;
}

interface PageContext {
  setProfile: (profile: any) => void;
  profile: { username: string };
  setAwardCount: (count: number) => void;
}

const Button: React.FC<Profile> = (props) => {
  const {label, disabled, addClasses, action} = props;
  const {setProfile} = useContext(Context) as unknown as PageContext;
  const navigate = useNavigate();

  const handleActions = () => {
    const navigateTo = (path) => {
      try {
        navigate(path);
      } catch (error) {
        console.error("Navigation error:", error);
      }
    };
    switch (action) {
      case "handleLogout":
        handleLogout();
        break;
      case "handleDashboard":
        navigateTo("/dashboard");
        break;
      case "handleLogin":
        navigateTo(`/login`);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    if (window.confirm("Logout! Are you sure?")) {
      setProfile({});
      navigate("/login");
    }
  };

  return (
    <button
      aria-label={label}
      className={addClasses}
      onClick={() => handleActions()}
      disabled={disabled}>
      {label}
    </button>
  );
};
export default Button;
