import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { config } from "../../config";
import { Context } from "../../Context";
import { headersBasic } from "../../utils/headers";
import { PASSWORD_REGEX, EMAIL_REGEX } from "../../utils";
import bcrypt from "bcryptjs";
import Heading from "../Heading";
import PasswordInputField from "./PasswordInputField";
import PasswordInputFieldConfirm from "./PasswordInputFieldConfirm";
import TitleInputField from "./TitleInputField";
import EmailInputField from "./EmailInputField";
import TokenInputField from "./TokenInputField";
import Button from "./Button";
import Linklist from "./Linklist";

function Account({ journey }) {
  const formStateDefault = {name: "", email: "", token: "", password: "", confirmPassword: ""}
  const errorsDefault = {name: "*Required", email: "*Required", token: "*Required", password: "*Required", confirmPassword: "*Required"}
  const navigate = useNavigate();
  const location = useLocation();
  const {dictionary, profile, setProfile, setStaleDashboard } = useContext(Context);
  const {salt} = useContext(Context);
  const [formState, setFormState] = useState(formStateDefault);
  const [errors, setErrors] = useState(errorsDefault);
  const [disabled, setDisabled] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const isCheckmail = queryParams.get("checkmail");

  useEffect(() => {
    switch (journey) {
      case "signup":
        setDisabled(errors.name || errors.email || errors.password);
        break;
      case "login":
        setDisabled(errors.email || errors.password);
        break;
      case "recover":
        setDisabled(errors.email);
        break;
      default:
        break;
    }
  }, [journey, errors]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value.trim() }));
  };

  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMsg = "";

    switch (name) {
      case "password":
        errorMsg = validatePassword(value);
        setErrors((prevState) => ({ ...prevState, password: errorMsg }));
        break;
      case "confirmPassword":
        errorMsg = value !== formState.password ? "Confirm password is not matched" : "";
        setErrors((prevState) => ({ ...prevState, confirmPassword: errorMsg }));
        break;
      case "name":
        errorMsg = value.length === 0 ? "Name is empty" : "";
        setErrors((prevState) => ({ ...prevState, name: errorMsg }));
        break;
      case "email":
        errorMsg = validateEmail(value);
        setErrors((prevState) => ({ ...prevState, email: errorMsg }));
        break;
      case "token":
        errorMsg = value.length === 0 ? "Token is empty" : "";
        setErrors((prevState) => ({ ...prevState, token: errorMsg }));
        break;
      default:
        break;
    }
  };

  const validatePassword = (password) => {
    if (password.length === 0) return "Password is empty";
    if (!PASSWORD_REGEX.uppercase.test(password)) return "At least one Uppercase";
    if (!PASSWORD_REGEX.lowercase.test(password)) return "At least one Lowercase";
    if (!PASSWORD_REGEX.digits.test(password)) return "At least one digit";
    if (!PASSWORD_REGEX.specialChar.test(password)) return "At least one Special Character";
    if (!PASSWORD_REGEX.minLength.test(password)) return "At least minimum 8 characters";
    return "";
  };

  const validateEmail = (email) => {
    if (email.length === 0) return "Email is empty";
    if (!EMAIL_REGEX.test(email)) return "Must be a valid email";
    return "";
  };

  const handleEvent = async (e) => {
    e.preventDefault();
    const { name } = e.target;
    switch (name) {
      case "signup":
        await handleSignup();
        break;
      case "login":
        await handleLogin();
        break;
      case "recover":
        await handleRecover();
        break;
      default:
        break;
    }
  };

  const handleSignup = async () => {
    const url = config.apiRoot + `/user`;
    const hashedPassword = bcrypt.hashSync(formState.password.trim(), salt);
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: headersBasic,
      body: JSON.stringify({
        name: formState.name.trim(),
        email: formState.email.trim().toLowerCase(),
        password: hashedPassword,
      }),
    });
    await rawResponse.json();
    navigate("/login?checkmail=true");
  };

  const handleLogin = async () => {
    const url = config.apiRoot + `/user/auth`;
    const hashedPassword = bcrypt.hashSync(formState.password.trim(), salt);
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: headersBasic,
      body: JSON.stringify({
        email: formState.email.trim().toLowerCase(),
        password: hashedPassword,
      }),
    });
    //If succesfull populate auth states
    if (rawResponse.status === 200) {
      const response = await rawResponse.json();
      setProfile({ 
        username: response.username,
        token: response.data,
        status: "active" 
      });
      setStaleDashboard(true);
      navigate("/dashboard");
    } 
  };

  const handleRecover = async () => {
    const url = config.apiRoot + `/user/recover`;
    const rawResponse = await fetch(url, {
      method: "PUT",
      headers: {
        'Authorization' : 'Bearer ' + profile.token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: formState.email.trim().toLowerCase(),
      }),
    });
    await rawResponse.json();
    navigate("/login?checkmail=true");
  };

  return (
    <div className="form form-signup">
      <fieldset>
        <legend>
          <Heading Tag="h2" title={journey} />
        </legend>
      
        {isCheckmail && <p>{dictionary.config.activationEmailResp}</p>}

        {journey === "signup" && (
          <TitleInputField
            handleNameChange={handleInputChange}
            handleValidation={handleValidation}
            titleValue={formState.name}
            titleError={errors.title}
          />
        )}
        {(journey === "login" || journey === "signup" || journey === "recover" || journey === "confirm") && (
          <EmailInputField
            handleEmailChange={handleInputChange}
            handleValidation={handleValidation}
            emailValue={formState.email}
            emailError={errors.email}
          />
        )}
        {journey === "confirm" && (
          <TokenInputField
            handleTokenChange={handleInputChange}
            handleValidation={handleValidation}
            tokenValue={formState.token}
            tokenError={errors.token}
          />
        )}
        {(journey === "login" || journey === "signup" || journey === "confirm") && (
          <PasswordInputField
            handlePasswordChange={handleInputChange}
            handleValidation={handleValidation}
            passwordValue={formState.password}
            passwordError={errors.password}
          />
        )}
        {(journey === "signup" || journey === "confirm") && (
          <PasswordInputFieldConfirm
            handlePasswordChange={handleInputChange}
            handleValidation={handleValidation}
            confirmPasswordValue={formState.confirmPassword}
            confirmPasswordError={errors.confirmPassword}
          />
        )}
        <Button class journey={journey} disabled={disabled} handleEvent={handleEvent} />
        <Linklist />

      </fieldset>

      
    </div>
  );
}

export default Account;