/* Heading.tsx */

import React from 'react';

interface HeaderProps {
    title: string;
    Tag: keyof JSX.IntrinsicElements;
}

const Heading: React.FC<HeaderProps> = (props) => {
    let { title, Tag } = props;
    
    return <Tag 
                className="heading capitalize" 
                dangerouslySetInnerHTML={{
                    __html: title
                }} />
}

export default Heading