/* Footer.tsx */

import React, { useContext } from "react";
import { Context } from "../Context";
import { Link } from "react-router-dom";

interface Dictionary {
  config: {
    copyright: string;
  };
}

const Footer: React.FC = () => {
  const { dictionary } = useContext(Context) as unknown as  { dictionary: Dictionary };

  return (
    <footer>
      <FooterLinks />
      <FooterCopyright text={dictionary.config.copyright} />
    </footer>
  );
};

const FooterLinks: React.FC = () => (
  <div>
    <Link to="/about">About</Link>
    <Link to="/dish/vjquhzxtqagguyelwd2zha">Demo</Link>
    <Link to="/sitemap">Sitemap</Link>
    <Link to="/disclaimer">Disclaimer</Link>
  </div>
);

interface FooterCopyrightProps {
  text: string;
}

const FooterCopyright: React.FC<FooterCopyrightProps> = ({ text }) => (
  <p dangerouslySetInnerHTML={{ __html: text }} />
);

export default Footer;
