/* dictionary.js */

const dictionary = {
  meta: {
    title: "pestle<sub>Hub</sub>",
  },
  config: {
    email: "query@pestlehub.com",
    copyright: "&copy;2024 Pestlehub.com. All Rights Reserved.",
    loggedOut: "Successfully logged out",
    checkEmail:
      "An Activation Email has been sent to your registered email address.",
    checkJunk:
      "If after 10 mins you haven't recieved your activation email. Please check your email clients spam folder.",
    headingdDishSpices: "Herb, Seasoning, or Spice",
    headingdDishIngredients: "Ingredients",
    headingBasket: "Basket",
    headingShareOptions: "Sharing Options",
    headingBasketIngredients: "Ingredients",
    headingBasketSpices: "Spices",
    headingDashboard: "Dashboard",
    bookmarks: "Bookmarks",
    headingCreatedDishList: "Creations",
    headingDishBuilder: "Dish Builder",
    noBookmarks: "Currently No Bookmarks.",
    dishCreateListEmpty: "Currently No Dishes Created",
    missingIngredientPrompt: `Missing ingredients? Tell us by emailing <a href="mailto:query@pestlehub.com">query@pestlehub.com</a>`,
    promptBasketEstimate: "*Basket Cost Estimate",
    activationEmailResp: "Activation email will follow shortly. Please follow instructions for activation."
  },
  pages: {
    about: `<h2>Hey there, food enthusiast!</h2>
            <p>Welcome to &copy;PestleHub&trade;. We're all about culinary creativity and community. Whether you're a seasoned chef or a home cook, our platform has something for everyone. Explore our mouthwatering collection of dishes, from savory to sweet. And guess what? You can save your favorites, build your own recipes, and even generate handy shopping lists!</p>
            <h3>Features</h3>
            <p><strong>Browse Our Dish Collection:</strong> Explore our curated list of dishes from around the world. From appetizers to desserts, you'll find a diverse selection to inspire your next culinary adventure.</p>
            <h3>Features upon Registration</h3>
            <ul>
            <li><strong>Save Your Favorite Dishes</strong>: Keep track of dishes you love by saving them to your profile.</li>
            <li><strong>Build Your Own Dish</strong>: Get creative! Use our tools to customize and create your unique recipes.</li>
            <li><strong>Generate Shopping Lists</strong>: Plan your grocery trips efficiently with our shopping list feature.</li>
            </ul>
            <p>Join us today and let’s whip up some deliciousness.</p>
            <p>&copy;PestleHub&trade; Team</p>`,
    disclaimer: `<h2>Disclaimer</h2>
            <p>&copy;PestleHub&trade; operate the www.pestlehub.com website and services.</p>
            <h3>General Information</h3>
            <p>The information provided by &copy;PestleHub&trade; on www.pestlehub.com is for general informational purposes only. All information on the Service is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Service.</p>
            <h3>External Links Disclaimer</h3>
            <p>The Service may contain (or you may be sent through the Service) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>
            <p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Service or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>
            <h3>Professional Disclaimer </h3>
            <p>Any medical, legal, fitness information is provided for general informational and educational purposes only and is not a substitute for professional advice.</p>
            <p>Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of medical, legal, fitness advice. The use or reliance of any information contained on this site is solely at your own risk.</p>
            <h3>Cookies and Privacy</h3>
            <h3>Cookies</h3>
            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <h3>Privacy</h3>
            <p>We value your privacy and are committed to protecting your personal data. Our Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read our Privacy Policy carefully to get a clear understanding of how we handle your personal data.</p>
            <h3>Limitation of Liability</h3>
            <p>In no event shall &copy;PestleHub&trade;, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.</p>
            <h3>Changes to This Disclaimer</h3>
            <p>We may update our Disclaimer from time to time. We will notify you of any changes by posting the new Disclaimer on this page.</p>
            <p>You are advised to review this Disclaimer periodically for any changes. Changes to this Disclaimer are effective when they are posted on this page.</p>
            <h3>Contact Us</h3>
            <p>If you have any questions about this Disclaimer, you can contact us:</p>
            <p>By email: query@pestlehub.com</p>`,
  },
  button: {
    back: "Back",
    backAria: "Return to Previous Page",
    homeAria: "Return to Home Page",
    top: "Top",
    topAria: "Go to Top of Page",
    print: "Print",
    printAria: "Print This Page",
    builder: "Builder",
    rename: "Rename",
    share: "Share",
    delete: "Delete",
    update:"Update",
    basket: "Fill Basket",
    createDish: "Dishbuilder",
    dishBuilderAdd: "Build",
    addToBasket: "Fill Basket",
    loginDishBuilder: "Login to Dish Builder",
    loginToSave: "Login to Save",
    saveDish: "Save Dish",
    bookmark: "Bookmark",
    openDishBuilder: "Dish Builder",
    currentPrice: "",
    addPrice: "Add",
    revealPrediction: "*Add 5 Prices To Reveal Basket Cost.",
    thankyou: "Thankyou",
    logout: "Logout",
    removeSection: "Remove Section"
  },
};

export default dictionary;
