// step2.tsx
/* Step 2 Method */

import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom"
import Heading from "../Heading";
import { MdDeleteForever } from "react-icons/md";

interface Ingredient {
  name: string;
  quantity: number;
  unit_ID?: number;
  state_ID?: number;
}

interface Method {
  heading: string;
  body: string;
}

interface Dish {
  title: string;
  ingredients: Ingredient[];
  methods: Method[];
  time_prep?: number;
  time_cook?: number;
  country_ID?: number;
  image?: string;
  publishedby: string;
}

interface StepTwoProps {
  dish: Dish;
  handleChange: (methods: Method[]) => void;
  next: () => void;
  back: () => void;
}
  
const StepTwo: React.FC<StepTwoProps> = React.memo(({ dish, handleChange, next, back }) => {
  const [heading, setTitle] = useState<string>('');
  const [body,  setBody] = useState<string>('');
  const [hasSubmited, setHasSubmited] = useState<boolean>(false);
  
  const addMethod = useCallback((heading: string, body: string) => {
      if (!heading.trim()) return;

      let updatedMethods: Method[];
      const newMethod: Method = { heading: heading, body: body };
      updatedMethods = [...dish.methods, newMethod];

      handleChange(updatedMethods);
      setHasSubmited(true)
      setTitle('');
      setBody('');
    },
    [dish.methods, handleChange]
  );
  
  const deleteMethod = useCallback((heading: string) => {
    const updatedMethods = dish.methods.filter(
      (method) => method.heading.toLowerCase() !== heading.toLowerCase()
    );
    handleChange(updatedMethods);
  }, [dish.methods, handleChange]);

  const resetMethod = useCallback(() => {
    setHasSubmited(false)
    setTitle('');
    setBody('');
  }, [setHasSubmited, setTitle, setBody]);

  return (
    <>
      <h2>Dishbuilder</h2>

      {!hasSubmited ? 
        <>
          <fieldset>

            <legend>
              <Heading Tag="h3" title="Methods" />
            </legend>

            <input
              type="text"
              name="title"
              placeholder="Step 1 : Prepare the Paste"
              onChange={(e) => setTitle(e.target.value)} />

            <textarea
              name="body"
              placeholder="Place the roasted dry spices into the pestle and grind."
              onChange={(e) => setBody(e.target.value)} />

            <button
              className="button-cta"
              type="button" 
              onClick={() => addMethod(heading, body)}
              disabled={!heading.trim() || !body.trim()}>
              <span className="uppercase">Add</span>
            </button>
            
          </fieldset>
        </> : <p>Click <Link to="#" onClick={() => resetMethod()}>here</Link> to add more methods.</p> 
      }

      <fieldset>
          <legend>
              <Heading Tag="h3" title="Added" />
          </legend>

          {dish.methods && dish.methods.length > 0 ? (
              <ul>
                  {dish.methods.map((method, index) => (
                      <li key={index} className="full-width">
                          <button 
                              className="button-cta" 
                              onClick={() => deleteMethod(method.heading)}>
                            <h4 className="capitalize">{method.heading}</h4> 
                            <p>{method.body}</p>
                            {' '}<MdDeleteForever /> 
                          </button>
                      </li>
                  ))}
              </ul>
          ) : (
              <p>Currently no methods. Use the form to add methods to your dish.</p>
          )}
      </fieldset>
      <hr />
      <StepNav back={back} next={next} />
      <br />
      <br />
      <hr />
    </>
  );
});

const StepNav: React.FC<{ back: () => void, next: () => void }> = ({ back, next }) => {
  return  <nav>
              <p>Step 2 of 3 : Methods</p>
              <span className="back">
                <Link to="#" onClick={back}>{' < '}BACK</Link>
              </span>
              <Link to="#" className="next" onClick={next}>Next{' > '}</Link>
            </nav>
          }


export default StepTwo;