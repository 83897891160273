/* Disclaimer.js */

import React, { useContext } from "react";
import { Context } from '../Context';

interface Dictionary {
  pages: {
    disclaimer: string;
  };
}

const Disclaimer: React.FC = () => { 
  const { dictionary } = useContext(Context) as unknown as { dictionary: Dictionary };
  return <main 
    className = "screen" 
    dangerouslySetInnerHTML = { {__html: dictionary.pages.disclaimer } } /> 
};

export default Disclaimer;