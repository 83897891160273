// step1.tsx 

import React, { useState, useCallback } from "react";
import Search from "./Search"
import { Link } from "react-router-dom"
import Heading from "../../components/Heading";
import { MdDeleteForever } from "react-icons/md";
import states from '../../jsons/states.json'
import units from '../../jsons/units.json'

interface Ingredient {
    name: string;
    quantity: number;
    unit_ID?: number;
    state_ID?: number;
}

interface Method {
    heading: string;
    body: string;
}

interface Dish {
    title: string;
    ingredients: Ingredient[];
    methods: Method[];
    time_prep?: number;
    time_cook?: number;
    country_ID?: number;
    image?: string;
    publishedby: string;
  }

interface StepOneProps {
    dish: Dish;
    handleChange: (e: React.ChangeEvent<HTMLInputElement> | Ingredient[] | Method[]) => void;
    next: () => void;
}
  
const StepOne: React.FC<StepOneProps> = React.memo(({ dish, handleChange, next }) => {
    const [ingredientName, setIngredientName] = useState<string>('');
    const [quantity, setQuantity] = useState<number>(1);
    const [unitID, setUnitID] = useState<number>(-1);
    const [stateID, setStateID] = useState<number>(-1);

    if (ingredientName || quantity || unitID || stateID ) {
        /*console.log("dsfsdfds", ingredientName, quantity, unitID, stateID);*/
    }

    const addIngredient = useCallback((ingredientName: string, quantity: number, unit_ID: number, state_ID: number) => {

        if (!ingredientName.trim()) return;

        const existingIngredient = dish.ingredients.find((ingredient) => ingredient.name.toLowerCase() === ingredientName.toLowerCase());

        let updatedIngredients: Ingredient[];

        if (existingIngredient) {
            updatedIngredients = dish.ingredients.map((ingredient) =>
                ingredient.name.toLowerCase() === ingredientName.toLowerCase()
                ? { 
                    ...ingredient, 
                    quantity: ingredient.quantity + 1, 
                    unit_ID: unit_ID || ingredient.unit_ID,
                    state_ID: state_ID || ingredient.state_ID
                }
                : ingredient
            );
        } else {
            const newIngredient: Ingredient = { 
                name: ingredientName, 
                quantity: quantity, 
                unit_ID: unit_ID,
                state_ID: state_ID
            };
            updatedIngredients = [...dish.ingredients, newIngredient];
        }

        handleChange(updatedIngredients);
        setIngredientName('');
        setQuantity(1);
        setUnitID(0);
        setStateID(0);

    }, [dish.ingredients, handleChange]);

    const deleteIngredient = useCallback((ingredientName: string) => {
        if (dish.ingredients.length === 1) {
            //alert("WONT: " + ingredientName + dish.ingredients.length);
        }
        const updatedIngredients = dish.ingredients.filter((ingredient) => ingredient.name.toLowerCase() !== ingredientName.toLowerCase());
        handleChange(updatedIngredients);
    }, [dish.ingredients, handleChange]);

    return (
        <>
            <h2>Dishbuilder</h2>

            <fieldset>
                <legend>
                    <Heading 
                        Tag="h3" 
                        title="Ingredients" />
                </legend>
                <Search onAddIngredient={addIngredient} />
            </fieldset>

            <fieldset>

                <legend>
                    <Heading Tag="h3" title="Added" />
                </legend>

                {dish.ingredients && dish.ingredients.length > 0 ?  <ul>
                    {dish.ingredients.map((ingredient, index) => (
                        <li key={index}>
                            <button
                                type="button"
                                className="button-cta" 
                                onClick={() => deleteIngredient(ingredient.name)}>
                                <span>
                                    <sup>
                                        {ingredient.quantity}
                                        { units
                                            .filter((unit) => 
                                                Number(unit.index) === ingredient.unit_ID 
                                                && Number(unit.index) !== -1)
                                            .map((unit, i) => (
                                                <span key={i}>
                                                    {unit.code}
                                                </span>)
                                        )}
                                    </sup>
                                </span>
                                <span className="capitalize">
                                    {ingredient.name}
                                </span>
                                <span>
                                    <sup>
                                        { states
                                            .filter((state) => 
                                                Number(state.index) === ingredient.state_ID
                                                && Number(state.index) !== -1)
                                            .map((state) => (
                                                <span key={state.index}>
                                                    {state.name}
                                                </span>
                                            ))}
                                    </sup>
                                </span>
                                {' '}<MdDeleteForever /> 
                            </button>
                        </li>
                    ))}
                </ul> : <p>Currently no ingredients. Use the search to add some to your dish.</p>}

            </fieldset>
            <hr />
            <StepNav next={next} />
            <br />
            <br />
            <hr />
        </>
    );
});


const StepNav: React.FC<{ next: () => void }> = ({ next }) => {
    return  <nav>
                <p>Step 1 of 3 : Ingredients</p>
                <Link 
                    to="#" 
                    className="next" 
                    onClick={next}>
                    Next{' > '}
                </Link>
            </nav>
            }

export default StepOne;