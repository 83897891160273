/* List/ListBookmarks.js */

import React, { useContext } from "react";
import { Context } from "../../Context";
import { Link } from "react-router-dom";
import { convertAmountFriendly } from "../../utils";

const ListBookmarks = ({ data }) => {
  const {bookmarks} = data;
  const {dictionary} = useContext(Context);

  if (!data || data.length === 0) {
    return (
      <p dangerouslySetInnerHTML={{ __html: dictionary.config.noBookmarks }} />
    );
  }
  return <ul>
        {bookmarks.map((item, index) => (
          <li key={index}>
            <Link to={`/dish/${item.saved_IDX}`}>
              <sup>
                {item.amount && convertAmountFriendly(item.amount)}
                {item.unit && item.unit !== "None" && item.unit}
              </sup>
              <span 
                className="capitalize" 
                dangerouslySetInnerHTML={{ __html: item.ingredient || item.title }} />
            </Link>
          </li>
        ))}
      </ul>
};

export default ListBookmarks;
