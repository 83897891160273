/* Dish.tsx */

import React, { lazy, Suspense } from "react";
import { renderLoader } from "../utils/renderLoader";

const Screen = lazy(() => import("./../components/Screen"));

const Dish: React.FC = () => {
  return (
    <main>
      <Suspense fallback={renderLoader()}>
        <Screen />
      </Suspense>
    </main>
  );
};

export default Dish;
