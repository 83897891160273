import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context";
import StepOne from "./Step1";
import StepTwo from "./Step2";
import StepThree from "./Step3";
import ReactGA from "react-ga4";
import "./MultiStepForm.css";

interface Ingredient {
  name: string;
  quantity: number;
  unit_ID?: number;
  state_ID?: number;
}

interface Method {
  heading: string;
  body: string;
}

interface Dish {
  title: string;
  ingredients: Ingredient[];
  methods: Method[];
  time_prep?: number;
  time_cook?: number;
  country_ID?: number;
  image?: string;
  publishedby: string;
}

/* MultiStepForm */
const MultiStepForm = () => {
  
  const navigate = useNavigate();
  const { setPendingDishes, profile } = useContext(Context) as any;
  const username =  profile.username;
  const [step, setStep] = useState(1);
  const [dish, setDish] = useState<Dish>({ 
    id: '', 
    title: '', 
    ingredients: [], 
    methods: [], 
    time_prep: 0, 
    time_cook: 0,
    country_ID: -1, 
    publishedby: username 
  });
  const next = useCallback(() => setStep((prev) => prev + 1), []);
  const back = useCallback(() => setStep((prev) => prev - 1), []);
  
  /**
   * Handles changes to the form inputs and updates the dish state accordingly.
   * 
   * @param e - The change event or an array of ingredients or methods.
   * 
   * If `e` is an array:
   * - If the array contains ingredients (determined by checking if the first element has a `quantity` property), 
   *   updates the `ingredients` field of the dish state.
   * - Otherwise, updates the `methods` field of the dish state.
   * 
   * If `e` is a change event:
   * - Extracts the `name` and `value` from the event target and updates the corresponding field in the dish state.
   */
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Ingredient[] | Method[]) => {
    if (Array.isArray(e)) {
      if (e.length > 0 && 'quantity' in e[0]) {
        setDish((prevDish) => ({...prevDish, ingredients: e as Ingredient[] }));
      } else {
        setDish((prevDish) => ({...prevDish, methods: e as Method[] }));
      }
    } else if ('target' in e) {
      const { name, value } = e.target;
      setDish((prevDish) => ({...prevDish, [name]: value }));
    }
  }, []);

  /**
   * Handles the form submission event.
   *
   * @param {React.FormEvent} e - The form event object.
   * @returns {void}
   *
   * This function prevents the default form submission behavior,
   * creates a clone of the current dish object, updates the pending dishes state
   * with the new dish, and navigates to the dashboard page.
   */
  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    const dishClone = { ...dish };
    setPendingDishes((prev) => [...prev, { dish: dishClone }]);
    
    // Track the event with Google Analytics
    ReactGA.event({
      category: "Dishbuilder",
      action: "Submit Dish",
    });
    /*  ReactGA.event({
        category: "Dishbuilder",
        action: "Submit",
        label: "your label", // optional
        value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image});*/
    navigate("/dashboard");

  }, [dish, setPendingDishes, navigate]);

  return (
    <section id="multi-step-form">
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <StepOne 
            dish={dish} 
            handleChange={handleChange} 
            next={next} />
        )}
        {step === 2 && (
          <StepTwo
            dish={ dish }
            handleChange={ handleChange }
            back={ back }
            next={ next }
          />
        )}
        {step === 3 && (
          <StepThree
            dish={dish}
            handleChange={handleChange}
            back={back}
            handleSubmit={handleSubmit}
          />
        )}
      </form>
    </section>
  );
};

export default MultiStepForm;