/* List/ListCreations.js */

import React from "react";

interface DishesPending {
  pendingDishes: Dish[];
}

interface Dish {
  title?: string;
  ingredients: Ingredient[];
  methods: Method[];  
  time_prep?: number;
  time_cook?: number;
  country_ID?: number;
  image?: string;
  publishedby: string;
}

interface Ingredient {
  name: string;
  quantity: number;
  unit: string;
}

interface Method {
  heading: string;
  body: string;
}

const ListPending: React.FC<DishesPending> = ({ pendingDishes }) => {
  return Array.isArray(pendingDishes) && pendingDishes.length > 0 && <ul>
    {pendingDishes.map((dish, index) => (
      <li key={index}>
        {dish.title}
      </li>
    ))}
  </ul>
}

export default ListPending;
