/* Home.tsx */

import React from 'react';
import MultiStepForm from '../components/MultiStepForm';

const Home = () => { 
  return <main className="screen">
        <MultiStepForm />
      </main>}

export default Home;