/* Login.tsx */

import React from "react";
import Account from "../components/Account";

const Login = () => { return <main className="screen">
    <Account journey='login' />
  </main>
};

export default Login;