// CurrentIngredients.tsx
import React from "react";
import states from '../../jsons/states.json'
import units from '../../jsons/units.json'

interface Ingredient {
    quantity: number;
    name: string;
    unit_ID?: number;
    state_ID?: number;
}

interface Dish {
    ingredients: Ingredient[];
}

const CurrentIngredients = ({ dish }: { dish: Dish }) => {
    return  <>
        <h4>Ingredients</h4>
        <ul> 
        { dish.ingredients && dish.ingredients.length > 0 ? 
            (dish.ingredients.map((ingredient, index) => (
                <li key={index}>
                    <span>
                        <sup>
                            <sup>
                                {ingredient.quantity >= 2 && ingredient.quantity}
                                {units.filter((unit) => 
                                        Number(unit.index) === ingredient.unit_ID 
                                        && Number(unit.index) !== -1)
                                        .map((unit, key) => (
                                            <span key={key}>
                                                {unit.code}
                                            </span>
                                ))}
                            </sup>
                        </sup>
                    </span>      
                    <span className="capitalize">
                        {ingredient.name}
                    </span>
                    {<span>
                        <sup>
                            { states
                                .filter((state) => 
                                    Number(state.index) === ingredient.state_ID
                                    && Number(state.index) !== -1)
                                .map((state) => (
                                    <span key={state.index}>
                                        {state.name}
                                    </span>
                                ))}
                        </sup>
                    </span>}
                </li>
            ))) : <li>{'No Ingredients added.'}</li> }
        </ul>
    </>
}

export default CurrentIngredients;