/* Idle.tsx */

import { useContext } from "react";
import { Context } from "../Context";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

interface PageContext {
  setProfile: (profile: any) => void;
  setStaleDashboard: (stale: boolean) => void;
}

function useIdleHandler() {
  const { setProfile, setStaleDashboard } = useContext(Context) as unknown as PageContext;
  const navigate = useNavigate();
  
  const handleIdle = () => {
    //removeItem("token");
    setProfile({});
    setStaleDashboard(false);
    navigate("/login");
  };
  
  return handleIdle;
}

export default function Idle() {
  const handleIdle = useIdleHandler();
  const TIMEOUT_DURATION = 15 * 60 * 1000;
  useIdleTimer({ onIdle: handleIdle, timeout: TIMEOUT_DURATION });
  return null;
}