// CurrentIngredients.tsx

import React from "react";

const CurrentMethods = ({ dish }) => {
  return  <>
      <h4>Methods</h4>
      <ul> 
        { dish.methods && dish.methods.length > 0 ? 
          (dish.methods.map((method, index) => (
            <li key={index}>
              <h4 className="capitalize">{method.title}</h4>
              <p className="capitalize">{method.body}</p>
            </li>
          ))) : "No Methods added." }
      </ul>
  </>
}

export default CurrentMethods;