/* Layout.tsx */

import React, { useContext } from "react";
import { Context } from "../Context";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import StatusBadge from "../components/Badges/StatusBadge";
import PageControls from "../components/PageControls";
import Footer from "../components/Footer";

interface Dictionary {
  meta: {
    title: string
  }
}

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dictionary } = useContext(Context) as unknown as { dictionary: Dictionary };
  const isHomePage = location.pathname === "/";
  const pagename = location.pathname.split("/")[1];

  const moveHome = () => {
    navigate("/Home");
  };

  return (
    <div className={"container " + (pagename && "container_" + pagename) + " unselectable"} > 
      <header>
        <h1>
          <Link 
            to={"/"} 
            onClick={moveHome}>
            <span dangerouslySetInnerHTML={{ __html: dictionary.meta.title }} />
            <em style={{"fontSize" : "0.58em", "margin": "0 0 2px 0"}}>Reciepe eWallet</em>
          </Link>
        </h1>
        <StatusBadge />
      </header>
      <Outlet />   
      { !isHomePage && 
        <PageControls position="bottom" /> }
      <Footer />
    </div>
  );
};
export default Layout;
