/* List/ListCreations.js */

import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Context } from "../../Context";
import { config } from "../../config";

const ListCreations = ({ data }) => {
  const navigate = useNavigate();
  const {dictionary, profile, setStaleDashboard} = useContext(Context);
  const {username, token} = profile;
  const {creations} = data;
  const dishUserUrl = `${config.apiRoot}/dish/user/${username}`;

  const handleDeleteDish = (dishPID) => {
    if (window.confirm("You are about to remove a Dish! Are you sure?") === true) {
      (async () => {
        await fetch(`${dishUserUrl}/${dishPID}`, {
              method: "DELETE",
              headers: {
                'Authorization' : 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
          }
        )
        .then(() => setStaleDashboard(true))
        .catch((error) => {
          console.log(` ${error.message}`);
        });
      })();
    }
  };

  if (!creations || creations.length === 0) {
    return <p>{dictionary.config.noCreations}</p>;
  }

  return (
    <ul>
      {creations.map((item) => (
        <li key={item.dish_PID}>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/dish/${item.dish_PID}`);
            }}
          >
            {dictionary.button.share}
          </Link> <Link
            className="cta-warning"
            onClick={() => handleDeleteDish(item.dish_PID)}>
            {dictionary.button.delete}
          </Link> <span 
            className="capitalize" 
            dangerouslySetInnerHTML={{ __html: item.title }} />
        </li>
      ))}
    </ul>
  );
};

export default ListCreations;
