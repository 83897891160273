function TitleInputField({
  handleValidation,
  handleNameChange,
  titleValue,
  titleError,
}) {
  return (
    <>
      <input
        autoComplete="off"
        name="name"
        onChange={handleNameChange}
        onBlur={handleValidation}
        placeholder="Title"
        type="text"
        value={titleValue}
      />
      <em className="text-danger">{titleError}</em>
    </>
  );
}

export default TitleInputField;
