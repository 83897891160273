/* Sitemap.js */

import React from "react";
import SearchList from '../components/SearchList';

const Sitemap = () => { 
  return <main className="screen">
    <SearchList />
  </main>};

export default Sitemap;