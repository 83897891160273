import React, { useState, useEffect, useCallback } from 'react';
import { config } from '../../config';
import ConnectionStatus from "../../components/ConnectionStatus";
import states from '../../jsons/states.json'
import units from '../../jsons/units.json'

interface SearchProps {
  onAddIngredient: (
    ingredientName: string,
    quantity: number,
    unit_ID: number,
    state_ID: number
  ) => void;
}

const Search: React.FC<SearchProps> = ({ onAddIngredient }) => {
  //const [isConnected, setIsConnected] = useState(true);
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<number>(0);
  const [selectedState, setSelectedState] = useState<number>(0);

  const handleSearch = useCallback(async (searchQuery: string) => {
    try {
      const response = await fetch(`${config.apiRoot}/ingredients/search?q=${searchQuery}`);
      const data = await response.json();
      setSearchResults([{
          ingredient_ID: 0,
          name: searchQuery,
        }, ...data,
      ]);

    } catch (error) {
      console.error('Error fetching search results:', error);
      //setIsConnected(false);
    }
  }, []);

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement>, ingredient: string) => {
    e.preventDefault();
    const quantity = 1;
    onAddIngredient(ingredient, quantity, selectedUnit, selectedState);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUnit(parseInt(e.target.value, 10));
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    if (query.length >= 3) {
      handleSearch(query);
    }
  }, [query, handleSearch]);

  return (
    <>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search e.g. Red Cabbage" />

      <ConnectionStatus />
    
      {searchResults.length ? <p>{searchResults.length} Ingredients Found.</p> : <p>No search results found.</p>}
      
      {searchResults.length > 0 && <ul>
        {searchResults.map((item) => (
            <li key={item.ingredient_ID}>
                <button
                  className="button-cta"  
                  onClick={(e) => handleAdd(e, item.name)}>
                  <p>
                    1 {' X '} <select onChange={handleSelectChange} value={selectedUnit}>
                      {units.map((unit) => (
                        <option key={unit.index} value={unit.index}>
                        {unit.name}
                        </option>
                      ))}
                    </select>
                  {' of '}
                  <select onChange={handleStateChange} value={selectedState}>
                    {states.map((state) => (
                        <option key={state.index} value={state.index}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  </p> 
                  <span className="capitalize">{item.name}</span>*
                </button>
            </li>
        ))}
    </ul>}
    {!searchResults.length || <em>*Click an option multiple times to add more of the same Ingredient.</em>}
    </>
  );
};

export default Search;