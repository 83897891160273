/* Measure.tsx */
import React from "react";
import units from "../jsons/units.json";

const Measure: React.FC = () => { 
    return <main className="screen">
        <h2>Measures</h2>
        <ul>
            {units.map((unit, i) => (
                <li key={unit.index}>
                   <span className="capitalize">{unit.name}</span> <sup>{unit.code}</sup>
                </li>
            ))}
        </ul>
    </main>
  };

export default Measure;