import React from "react";
import PropTypes from "prop-types";

function Button({ journey, disabled, handleEvent }) {
  return (
    <button
      className="button-cta uppercase"
      value={journey}
      name={journey}
      id={journey}
      disabled={disabled}
      onClick={handleEvent}>
      {journey}
    </button>
  );
}

Button.propTypes = {
  journey: PropTypes.string.isRequired,
  disabled: PropTypes.any.isRequired,
  //disabled: PropTypes.bool.isRequired,
  handleEvent: PropTypes.func.isRequired,
};

export default Button;