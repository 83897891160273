/* DashboardPallete.tsx */
import React, {useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { config } from './../../config';
import { Context } from "./../../Context";
import dictionary from "./../../dictionary";
import Button from "./../../components/Button";
import Heading from "./../../components/Heading";
import ListCreations from "./../../components/Lists/ListCreations";
import ListBookmarks from "./../../components/Lists/ListBookmarks";
import ListPending from "./../../components/Lists/ListPending";

/*interface Dishes {
  dishes: Dish[];
}*/

interface Dish {
  title: string;
  ingredients: Ingredient[];
  methods: Method[];  
  time_prep?: number;
  time_cook?: number;
  country_ID?: number;
  image?: string;
  publishedby: string;
}

interface Ingredient {
  name: string;
  quantity: number;
  unit: string;
}

interface Method {
  heading: string;
  body: string;
}

interface Dictionary {
  profile: {
    username: string;
    token: string;
    status: string;
  },
  pendingDishes: Dish[],
  setPendingDishes: (dishes: Dish[]) => void,
  setProfile: (arg0: string[]) => void,
  setStaleDashboard: (arg0: boolean) => void,
  isStaleDashboard: boolean
}

const DashboardPallete = () => {
  const {profile, pendingDishes, setPendingDishes, setStaleDashboard, isStaleDashboard} = useContext(Context) as unknown as Dictionary;
  const {status, username, token} = profile
  const isActive = status === "active";
  const [userData, setUserData] = useState<string[]>([]);
  const [response, setResponse] = useState<string[]>([]);
  const dishUserUrl = `${config.apiRoot}/dish/user/${username}`;
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!isActive){
      navigate('/login')
    } else {
      setStaleDashboard(true)
    }
  }, [isActive, navigate, setStaleDashboard]);

  useEffect(() => { 
    const handlePendingDish = async (dish: Dish) => {
      try {
        fetch(`${config.apiRoot}/dishes`, { 
          method: "POST", 
          headers: {
            'Authorization' : 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dish)
        })
        .then((response) => {
          if (!response.ok) {
            console.log(`${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => setResponse((prevResponse) => [...prevResponse, data]))
        .then(() => setStaleDashboard(true))
        .catch((error) => {
          console.log(` ${error.message}`);
        });
  
      } catch (error) {
        console.error('Error fetching search', error);
      }
    };

    if (isActive && username !== "" && pendingDishes.length >= 1) {
      console.log("username: ", username);
      console.log("Pending Dishes: ", pendingDishes);

      pendingDishes.forEach(dish => {
        if (!dish.dish.publishedby) {
          dish.dish.publishedby = username;
        }
        handlePendingDish({ "dishes" : [dish] });
      });
      setPendingDishes([]);
    }
  }, [pendingDishes, setPendingDishes, isActive, username, setStaleDashboard, token]);

  useEffect(() => {
    if (isActive && isStaleDashboard) {
      const getData = async (dishUserUrl) => {
          fetch(dishUserUrl, { 
            method: "GET", 
            headers: {
              'Authorization' : 'Bearer ' + token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            if (!response.ok) {
              console.log(`${response.statusText}`);
            }
            return response.json();
          })
          .then((json) => setUserData(json.user))
          .then(() => setStaleDashboard(false))
          .catch((error) => {
            console.log(` ${error.message}`);
          });
      };
      getData(dishUserUrl);
    }
  }, [username, token, isActive, dishUserUrl, isStaleDashboard, setStaleDashboard, setUserData]);

  const Links: React.FC = () => {
    return <p>Return to <Link to={"/"}>Homepage</Link>, or <Link to={"/login/"}>login</Link>.</p>}
  
  const LogOutButton: React.FC = () => {
    return <Button
              action="handleLogout"
              addClasses="button-cta uppercase"
              label={dictionary.button.logout}
              disabled={false}
              id="logoutButton"
              table="" /> }
  
  return isActive ? <>
    <fieldset>
          
      <legend>
        <Heading Tag="h3" title={dictionary.config.headingCreatedDishList} />
      </legend>

      <ListCreations data={userData} />

      {response.length > 0 && <p>{response[0]['message']}</p>}
      
      <ListPending pendingDishes={pendingDishes} />

      <Link className="button-cta" to="/">
        <span className="uppercase">Dishbuilder</span>
      </Link>

    </fieldset>

    <fieldset>
      <legend>
        <Heading Tag="h3" title={dictionary.config.bookmarks} />
      </legend>

      <ListBookmarks data={userData} />

      <p>Dish bookmarks are added to your <Link to="/basket">Basket</Link>.</p>
      <p>For Inspiration try the <Link to="/sitemap">Search</Link>.</p>
    
    </fieldset>

    <hr />
    <LogOutButton />
  </> : <Links /> };

export default DashboardPallete;
