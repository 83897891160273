/* index.js */

//import { StrictMode } from 'react';
import ReactDOM from "react-dom/client";
import {useEffect, useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Context} from "./Context";
import Background from "./components/Background/Background";
import About from "./pages/About";
import Basket from "./pages/Basket";
import Dashboard from "./pages/Dashboard";
import Disclaimer from "./pages/Disclaimer";
import Dish from "./pages/Dish";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Measure from "./pages/Measure";
import NoPage from "./pages/NoPage";
import Recover from "./pages/Recover";
import Sitemap from "./pages/Sitemap";
import Signup from "./pages/Signup";
import Verify from "./pages/Verify";
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import Idle from "./components/Idle";
import dictionary from "./dictionary";
import { config } from "./config";
import "./App.css";
/* Google Analytics and Hotjar */
import ReactGA from "react-ga4";
// import { hotjar } from 'react-hotjar';

export default function App() {
  const salt = config.salt;
  const [profile, setProfile] = useState({});
  const [pendingDishes, setPendingDishes] = useState([]);
  const [awardCount, setAwardCount] = useState(0);
  const [costBasket, setCostBasket] = useState(0.0);
  const [isStaleDashboard, setStaleDashboard] = useState(false);
  const [inputs, setInputs] = useState([{ heading: "", category: "", description: "", image: "", position: "", body: "", ingredients: []}]);

  useEffect(() => {
    ReactGA.initialize("G-MY8QTVQLE9");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
    // Initialize Hotjar
    // hotjar.initialize(5220677, 6); // Replace with your Hotjar ID and version
  }, []);

  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Context.Provider value={{ dictionary, profile, setProfile, awardCount, setAwardCount, costBasket, setCostBasket, pendingDishes, setPendingDishes, isStaleDashboard, setStaleDashboard, inputs, setInputs, salt }}>
        <Background />
        <ScrollToTop />
        <Idle />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/basket" element={<Basket />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/dish/:dishId" element={<Dish />} />
            <Route path="/login" element={<Login />} />
            <Route path="/measure" element={<Measure />} />
            <Route path="/recover" element={<Recover />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Context.Provider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// <StrictMode></StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
