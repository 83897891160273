/* Basket.tsx */

import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { config } from '../config';
import { Context } from "../Context";
import { useNavigate } from "react-router-dom";
import { renderLoader } from "../utils/renderLoader";
const ListBasket = lazy(() => import("../components/Lists/ListBasket"));

interface Dictionary {
  profile: {
    status: string;
    username: string;
    token: string;
  },
  dictionary: { 
    config: { 
      headingBasket: string; 
      promptBasket: string; 
      headingBasketIngredients: string 
    } 
  },
  setBasket: (arg0: string[]) => void,
  setAwardCount: (arg0: number) => void,
  setCostBasket: (arg0: number) => void,
  isStaleBasket: (arg0: boolean) => void,
  setStaleBasket: (arg0: boolean) => void,
}

const Basket: React.FC = () => {
  const navigate = useNavigate();
  const { profile, dictionary, setAwardCount, setCostBasket} = useContext(Context) as unknown as Dictionary;
  const [isStaleBasket, setStaleBasket] = useState(false);
  const [basketData, setBasketData] = useState();
  const {status, username, token} = profile;
  const isActive = status === "active";
  const basketApi = `${config.apiRoot}/basket/ingredients/${username}`;

  useEffect(() => {
    if (!isActive){
      navigate('/login')
    } else {
      setAwardCount(0)
      setCostBasket(0.0)
    }
  }, [isActive, navigate, setAwardCount, setCostBasket]);

  useEffect(() => {
    if (isActive && !isStaleBasket) {
      const getData = async (path) => {
        await fetch(path, { 
          method: "GET",
          headers: {
            'Authorization' : 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          if (!response.ok) {
            console.log(` ${response.statusText}`);
          }
          return response.json();
        })
        .then((json) => setBasketData(json))
        .then(() => setStaleBasket(false))
        .catch((error) => {console.log(` ${error.message}`)});
      };
      getData(basketApi);
    }
  }, [basketApi, setBasketData, isStaleBasket, setStaleBasket, token, isActive]);

  return (
    <main className="screen">
      <BasketHeader heading={dictionary.config.headingBasket} /> 
      {/*prompt={dictionary.config.promptBasket} */}
      <Suspense fallback={renderLoader()}>
        <BasketContent
          basketData={basketData}
          heading={dictionary.config.headingBasketIngredients} />
      </Suspense>
    </main>
  );
};

interface BasketHeaderProps {
  heading: string;
  //prompt: string;
}

const BasketHeader: React.FC<BasketHeaderProps> = ({ heading/*, prompt*/ }) => (
  <>
    <h2 dangerouslySetInnerHTML={{ __html: heading }} />
    {/*<p>
      <em dangerouslySetInnerHTML={{ __html: prompt }} />
    </p>*/}
  </>
);

interface BasketContentProps {
  heading: string;
  basketData: [string];
}

const BasketContent: React.FC<BasketContentProps> = ({ heading, basketData  }) => (
  <>
    <h3 dangerouslySetInnerHTML={{ __html: heading }} />
    <ListBasket data={basketData} />
  </> 
);

export default Basket;