import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { config } from '../config';
import useFetch from "../hooks/useFetch";

interface Item {
  title: string;
  type: string;
  spice_ID?: number;
  dish_PID?: number;
}

const SearchList = () => {
  const ref = useRef(null);
  const [ filterName, setFilterName] = useState("")
  const [ data = [] ] = useFetch(`${config.apiRoot}/dish`)
  
  let clonedData: Item[] = data ? data.slice() : [];
  if(filterName && clonedData) { 
    clonedData = clonedData.filter(item => item && item.title && item.title.toLowerCase().includes(filterName.toLowerCase()))
  }

  const changeFilterName = (e) => {
    setFilterName(e.target.value)
  }

  return (
    <div className="form form-search">
      <p>
        <label>
          <input 
            id="name" 
            onChange={changeFilterName} 
            placeholder="Search" 
            ref={ ref } />
        </label>
      </p>

      <ul>
        {clonedData && clonedData.map(( item, i ) => <li key = { i }>
          <Link to={`/dish/${item.dish_PID}`}>
            <span className="capitalize">
              { item.title }
            </span>
          </Link> 
        </li>)} 
      </ul>

    </div>
)};
  
export default SearchList;
