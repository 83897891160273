/* StatusBadge.js */

import { useContext } from "react";
import { Context } from "../../Context";
import { Link, useNavigate} from "react-router-dom";
import IconSVGProfile from "../Slider/IconSVGProfile";

const StatusBadge = () => {
  const navigate = useNavigate();
  const { profile } = useContext(Context);

  const handleDashboard = () => {
    navigate('/dashboard')
  }

  return (
    <>
      {profile.status === "active" ? (
        <Link
          className="icon-login active"
          aria-label="Dashboard"
          onClick={() => handleDashboard()}
          to={"/dashboard"}
        >
          <IconSVGProfile />
        </Link>
      ) : (
        <Link
          className="icon-login"
          aria-label="Login to dashboard"
          to={"/login"}
        >
          <IconSVGProfile />
        </Link>
      )}
    </>
  );
};

export default StatusBadge;
