/* About.tsx */

import React, { useContext } from "react";
import { Context } from '../Context';

interface Dictionary{
  pages : {
    about: string
  }
}

const About: React.FC = () => { 
  const { dictionary } = useContext(Context) as unknown as { dictionary: Dictionary };
  return (
    <main className="screen">
      <AboutContent content={dictionary.pages.about} />
    </main>
  );
};

interface AboutContentProps {
  content: string;
}

const AboutContent: React.FC<AboutContentProps> = ({ content }) => (
  <section dangerouslySetInnerHTML={{ __html: content }} />
);

export default About;
