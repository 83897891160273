// step3.tsx
/* 
  Step 3 Review Step and Save
*/

import React from "react";
import { Link } from "react-router-dom"
import CurrentIngredients from "./CurrentIngredients";
import CurrentMethods from "./CurrentMethods";
import Heading from "../Heading";
import countrys from '../../jsons/countrys.json'

interface Ingredient {
  name: string;
  quantity: number;
  unit_ID?: number;
  state_ID?: number;
}

interface Method {
  heading: string;
  body: string;
}

interface Dish {
  title: string;
  ingredients: Ingredient[];
  methods: Method[];
  time_prep?: number;
  time_cook?: number;
  country_ID?: number;
  image?: string;
  publishedby: string;
}

interface StepThreeProps {
  dish: Dish;
  back: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSubmit: (e: React.FormEvent) => void;
}

const StepThree: React.FC<StepThreeProps> = React.memo(({ dish, handleChange, back, handleSubmit }) => (
  <>
    <h2>Dishbuilder</h2>

    <fieldset>

      <legend>
        <Heading 
          Tag="h3" 
          title="Title" />
      </legend>

      <input
        type="text"
        name="title"
        placeholder="Chicken Salad"
        value={dish.title}
        onChange={handleChange}
        required />

      <em>Optional</em>
      <select name="country_ID" onChange={handleChange} value={dish.country_ID}>
        {countrys.map((country) => (
            <option key={country.country_ID} value={country.country_ID}>
              {country.name}
            </option>
        ))}
      </select>

      <input
        type="text"
        name="time_prep"
        placeholder="Preparation Time (mins)"
        onChange={handleChange} />
      
      <input
        type="text"
        name="time_cook"
        placeholder="Cooking Time (mins)"
        onChange={handleChange} />

      {(!dish.title || dish.ingredients.length <= 2 || dish.methods.length < 1) && 
        <p className="error">
          <em>
            <sup>3</sup>Ingredients, <sup>1</sup>Method, and Title Required.
          </em>
        </p>
      }

      <button 
        className="button-cta" 
        onClick={handleSubmit}
        disabled={dish.title === '' || dish.ingredients.length <= 2 || dish.methods.length === 0}>
       <span className="uppercase">Save</span>
      </button>

    </fieldset>

    <fieldset>
      <legend>
        <Heading 
          Tag="h3" 
          title="Added" />
      </legend>
      <CurrentIngredients dish={dish} />
      <CurrentMethods dish={dish} />
    </fieldset>
    <hr />
    <StepNav back={back} />
    <br />
    <br />
    <hr />
  </>
));

const StepNav: React.FC<{ back: () => void }> = ({ back }) => {
  return  <nav>
              <p>Step 3 of 3 : Review & Save</p>
              <span className="back">
                <Link to="#" onClick={back}>{' < '}BACK</Link>
              </span>
            </nav>}

export default StepThree;