/* Dashboard.tsx */
import React from "react";
import DashboardPallete from "../components/Screen/DashboardPallete";

const Dashboard: React.FC = () => {
  return (
    <main className="screen">
      <h2>Dashboard</h2>
      <DashboardPallete />
    </main>
  )};

export default Dashboard;
